import React, { useContext } from 'react';

import { FormContext } from '../context';

import loadComponents from '../../Loadable';

const Label = loadComponents('form-label');

export default function Input({ field }) {
  const { handleChange } = useContext(FormContext);
  return (
    <label htmlFor={field.id}>
      <Label label={field.label} required={field.required} />
      <input onChange={handleChange} {...field} />
    </label>
  );
}
